.App {
  text-align: center;
}

.tips {
  background-image: linear-gradient(0deg, #df3a3c 0%, #fe705e 100%);
  font-family: Helvetica;
  font-size: 0.138889rem;
  color: #fff;
  letter-spacing: 0;
  line-height: 0.520833rem;
}

.banners {
  width: 10rem;
  height: 4.243056rem;
}

.banners .banner {
  width: 100%;
  height: 100%;
}

.tabs {
  height: 0.520833rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  border-bottom: 0.006944rem solid #dbb76c;
}

.tabs .tab {
  -webkit-flex: 1 1;
  flex: 1 1;
  line-height: 0.520833rem;
  font-size: 0.166667rem;
  font-weight: 500;
  color: #000;
  letter-spacing: 0;
  background: #fff;
  cursor: pointer;
}

.tabs .tab.current {
  background: #dbb76c;
  color: #fff;
}

.acts {
  margin-bottom: 0.229167rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.acts .empty {
  width: 100%;
  height: 1.736111rem;
  line-height: 1.736111rem;
  text-align: center;
}

.acts .act {
  margin-left: 0.375rem;
  margin-top: 0.229167rem;
  width: 4.4375rem;
  box-sizing: border-box;
  padding: 0.138889rem;
  background: #fff;
  box-shadow: 0 0 0.041667rem 0 rgba(0,0,0,.25);
  border-radius: 0.034722rem;
}

.acts .act .header {
  margin-bottom: 0.173611rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.acts .act .header .name {
  font-weight: 400;
  font-size: 0.152778rem;
  line-height: 0.256944rem;
  color: #000;
  letter-spacing: 0;
}

.acts .act .header .countdown {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.125rem;
  line-height: 0.256944rem;
}

.acts .act .header .countdown .val {
  width: 0.256944rem;
  height: 0.256944rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #fff;
  background: #dbb76b;
  border-radius: 0.013889rem;
}

.acts .act .header .countdown .val.bl {
  background: #353535;
}

.acts .act .header .countdown .des {
  width: 0.277778rem;
  height: 0.277778rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #353535;
}

.acts .act .body {
  width: 4.159722rem;
  height: 1.534722rem;
}

.acts .act .body .img {
  width: 100%;
  height: 100%;
  border-radius: 0.034722rem;
}

.spliter {
  background: #efefef;
  height: 0.145833rem;
}

.footer {
  height: 2.208333rem;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.footer .logoname {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}

.footer .logoname .logo {
  width: 0.347222rem;
  height: 0.347222rem;
}

.footer .logoname .sitename {
  margin-left: 0.125rem;
  font-family: Helvetica;
  font-size: 0.208333rem;
  color: #000;
  letter-spacing: 0.036111rem;
}

.footer .contact {
  text-align: left;
}

.footer .contact .head {
  font-weight: 500;
  font-size: 0.111111rem;
}

.footer .contact .body {
  margin-top: 0.166667rem;
  font-weight: 400;
  font-size: 0.097222rem;
  color: #000;
  letter-spacing: -0.002569rem;
  line-height: 0.152778rem;
}

.footer .service {
  text-align: left;
  font-weight: 400;
  font-size: 0.097222rem;
  color: #000;
  letter-spacing: -0.002569rem;
  line-height: 0.152778rem;
}

.footer .qrcode {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.footer .qrcode .code {
  width: 1.041667rem;
  height: 1.041667rem;
  background: #fff;
  border: 0 solid rgba(0,0,0,.25);
}

.footer .qrcode .qrguide {
  text-align: left;
  font-weight: 400;
  font-size: 0.090278rem;
  color: #000;
  letter-spacing: 0;
  line-height: 0.138889rem;
}

.footer .copyright {
  position: absolute;
  bottom: 0.048611rem;
  width: 100%;
  font-weight: 400;
  font-size: 0.090278rem;
  text-align: center;
  line-height: 0.25rem;
}